import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { useCookies } from "react-cookie";
import TextField from "@mui/material/TextField";
import userService from "../../Services/UserService";

import { toast } from "react-toastify";

export default function RegisterPopup({ bool, setbool, getUsers }) {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [cookies] = useCookies(["JWTtoken"]);
  const handleClose = () => {
    setbool(false);
    setfirstname("");
    setlastname("");
    setemail("");
    setpassword("");
  };

  const generatePassword = () => {
    setpassword(
      Math.random().toString(36).substring(5, 10) +
        Math.random().toString(36).substring(5, 10)
    );
  };
  const regFunc = () => {
    userService
      .Register(cookies.JWTtoken, { email, password, firstname, lastname })
      .then((data) => {
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        handleClose();
        getUsers();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <div>
      <Dialog
        open={bool}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            width: "200px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold" }}>Register User</DialogTitle>
        </Box>

        <DialogContent>
          <Box>
            <Box sx={{ display: "flex" }}>
              <TextField
                sx={{ mr: 1 }}
                label="First Name"
                value={firstname}
                onChange={(e) => {
                  setfirstname(e.target.value);
                }}
              />
              <TextField
                label="Last Name"
                value={lastname}
                onChange={(e) => {
                  setlastname(e.target.value);
                }}
              />
            </Box>
            <TextField
              label="email"
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
            />
            <Box sx={{ display: "flex" }}>
              <TextField
                label="Password"
                value={password}
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
              />
              <Button
                variant="text"
                onClick={(e) => {
                  generatePassword();
                }}
              >
                Generate Password
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  regFunc();
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </Box>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  handleClose();
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
